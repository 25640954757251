import { FC } from "react";

interface Props {
  className?: string;
  urlToOpen?: string;
}

const GooglePlayIcon: FC<Props> = (props: Props) => {
  return (
    <svg
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      onClick={
        props.urlToOpen == null
          ? undefined
          : (e) => {
              window.open(props.urlToOpen, "_blank");
            }
      }
    >
      <path d="M325.3 234.3L104.6 13l280.8 161.2-60.1 60.1zM47 0C34 6.8 25.3 19.2 25.3 35.3v441.3c0 16.1 8.7 28.5 21.7 35.3l256.6-256L47 0zm425.2 225.6l-58.9-34.1-65.7 64.5 65.7 64.5 60.1-34.1c18-14.3 18-46.5-1.2-60.8zM104.6 499l280.8-161.2-60.1-60.1L104.6 499z" />
    </svg>
  );
};

export default GooglePlayIcon;
